import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h3 class="panel-title">Willkommen auf unserer Homepage</h3>
                    </div>
                    <div class="panel-body">
                        <p><img width="160" height="120" hspace="10" align="right" src="/hitlogo.gif" alt="Hitlogo" />
                            Wir sind ein Dienstleistungsunternehmen mit Sitz in Wiener Neustadt. Unsere Hauptgebiete sind die Planung und Durchführung von Softwareprojekten und allgemeinen Dienstleistungen in der EDV</p>
                    </div>
                </div>

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h3 class="panel-title">Wir erstellen Individualsoftware</h3>
                    </div>
                    <div class="panel-body">
                        <p>Unsere Kernkompetenz liegt in der Programmierung von Individualsoftware in C# im .Net Umfeld. Alternativ kann auch ADP.NET Core und React für die Erstellung von Web-Lösungen verwendet werden.</p>
                    </div>
                </div>

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h3 class="panel-title">Unsere Zielgruppe</h3>
                    </div>
                    <div class="panel-body">
                        <ul>
                            <li>wenn Sie auf individuelle Softwareentwicklung Wert legen</li>
                            <li>wenn Sie auf sachgerechte Wartung, Erweiterung und Fehlerbehebung Ihrer Software Wert legen</li>
                            <li>wenn der Markt den Einsatz modernster Betriebsmittel und Technologien erfordert</li>
                            <li>wenn eine Projektabwicklung Ihre Ressourcen sprengt</li>
                            <li>wenn Ihre Ansprüche mit Standardsoftware nicht abgedeckt werden können</li>
                            <li>wenn Sie vor Software/Hardwareanschaffung stehen und unabhängigen Rat wollen</li>
                            <li>wenn Sie Ihren Mitarbeitern eine erstklassige Schulung und Betreuung sichern wollen</li>
                            <li>wenn Sie neu organisieren wollen</li>
                        </ul>
                    </div>
                </div>

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h3 class="panel-title">Unsere Firmengeschichte</h3>
                    </div>
                    <div class="panel-body">
                        <p>Unser Unternehmen wurde im Oktober 1994 gegründet. Anfänglich beschäftigten wir uns vor allem mit der Erstellung von branchenspezifischer Software. Begleitend wurden Netzwerke installiert und sowohl neue Hardware geliefert, als auch bestehende Hardware gewartet. Mit der Zeit wurde eine Spezialisierung auf Projektdienstleistungen durchgeführt. Heute sind wir in der Lage auch an großen und komplexen Projekten teilzunehmen.
Weiters haben wir uns auf Web-Applikationen mit Datenbankanbindung spezialisieren können.</p>
                    </div>
                </div>
            </div>
            
            //    <ul>
            //        <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
            //        <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
            //        <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
            //    </ul>
            //    <p>To help you get started, we have also set up:</p>
            //    <ul>
            //        <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
            //        <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
            //        <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
            //    </ul>
            //    <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
            //</div>

        );
    }
}

import React, { Component } from 'react';
export class Impressum extends Component {
    static displayName = Impressum.name;
    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        //this.incrementCounter = this.incrementCounter.bind(this);
    }
    //incrementCounter() {
    //    this.setState({
    //        currentCount: this.state.currentCount + 1
    //    });
    //}
    render() {
        return (<div>

            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">Kontakt</h3>
                </div>
                <div class="panel-body">
                    <p><img width="160" height="120" hspace="10" align="right" src="/hitlogo.gif" alt="Hitlogo" />
                        <strong>Heider Informations Technik</strong><br />Ing. Andreas Heider, BA<br />Tritremmelgasse 36<br />A-2700 Wiener Neustadt</p>
                    <p><strong>Telefon:</strong><a href="tel:+43262222226"> ++43 - 2622 - 22226</a></p>
                </div>
            </div>

            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">Informationen</h3>
                </div>
                <div class="panel-body">
                    <p><a href="/eip.pdf" target="_blank">	Erklärung zur Informationspflicht (Datenschutzerklärung)</a><br /></p>
                    <p><a href="http://firmena-z.wko.at/ecg.asp?MGID=127BC8448E1E8EF4BE9301FB4D11987E" target="_blank">ECG und Mediengesetzinfo anzeigen</a></p>
                </div>
            </div>

            





        </div>);
    }
    //<p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>
    //<button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
}

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Kontakt } from './components/Kontakt';
import { Impressum } from './components/Impressum';

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/impressum' component={Impressum} />
        <Route path='/kontakt' component={Kontakt} />
      </Layout>
    );
  }
}
